import Moment from "moment-timezone";

let defaultTZ = "America/New_York";
if (
  localStorage.getItem("UserTimeZone") !== "null" &&
  localStorage.getItem("UserTimeZone") !== ""
) {
  defaultTZ = localStorage.getItem("UserTimeZone");
}

const UserMoment = Moment.tz.setDefault(defaultTZ);

export const updateTimeZone = (timezone) => {
  if (timezone) Moment.tz.setDefault(timezone);
};
export default UserMoment;
